@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap");

#root {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}

* {
  font-family: "Plus Jakarta Sans", "sans-serif";
}


