.App {
  width: 100%;
  height: 100%;
  color: white;
  /* background: radial-gradient(circle at 50% 0%, #018585, transparent 60%),
    radial-gradient(circle at 100% 30%, #8a53e4d0, transparent 30%),
    radial-gradient(circle at 0% 50%, #351f58d0, transparent 30%),
    radial-gradient(circle at 0% 85%, #018585, transparent 20%); */
    background: linear-gradient(180deg, #0E1118 0%, #1D2330 100%);

}
input{
  color:black
}

.container {
  display: flex;
}

.menuContainer {
  width: 250px;
  padding: 5px 20px;
  border-right: 2px solid;
}

.contentContainer {
  padding: 5px 20px;
  width: 100%;
}
/* Custom CSS to hide scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}